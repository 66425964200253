.foot-desc-txt {
  margin-top: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #bfb259;
}

.sns-context {
  margin-top: 17px;
  width: 137px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.foot-category-title {
  width: 106px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #bfb259;
}

.foot-category-link {
  margin-top: 20px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
}

.foot-category-link a {
  color: #bfb259;
  text-decoration: none;
  cursor: pointer;
}

.find-us {
  display: flex;
  flex-direction: row;
}

.find-us span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  color: #bfb259;
}

.find-us div {
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #e3c08d;
}

.copyright-txt {
  margin-top: 17px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #e3c08d;
}

.foot-category-title:after {
  display: block;
  content: "";
  border-bottom: solid 2px #d7b686;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}

.foot-category-title:hover:after {
  transform: scaleX(1);
}

.logo-word {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Modern, clean font */
  font-size: 36px; /* Smaller font size */
  color: #bead5a; /* Main text color */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  letter-spacing: 1.5px; /* Space between letters for a modern look */
  text-transform: uppercase; /* Make the text uppercase */
  background: linear-gradient(
    90deg,
    #444c54,
    #bead5a,
    #bebfbf
  ); /* Gradient with specified colors */
  background-clip: text; /* Standard property for broader compatibility */
  -webkit-background-clip: text; /* Vendor prefix for WebKit browsers */
  -webkit-text-fill-color: transparent;
  display: inline-block; /* Ensure the text behaves like a block for styling */
}
