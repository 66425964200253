.homepage {
  width: 100%;
  height: auto;
}

/* STATISTICS */

.homepage .static-frame {
  width: 100%;
  height: 236px;
  background-color: #343434;
}
.homepage .static-frame .info {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.homepage .static-frame .info .static-box {
  height: 200px;
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage .static-frame .info .static-box .static-num {
  text-align: center;
  font-family: "Poppins";
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  color: #d7b686;
}

.homepage .static-frame .info .static-box .static-text {
  text-align: center;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  color: #d7b686;
}

/* POPULAR DISHES */

.homepage .popular-dishes-frame {
  width: 100%;
  height: auto;
  display: flex;
  background: #f8f8ff;
}

.homepage .popular-dishes-frame .popular-section {
  display: flex;
  flex-direction: column;
}

.homepage .popular-dishes-frame .popular-section .category-title {
  margin-left: 10px;
  font-family: "Roboto Serif";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.44px;
  color: #000;
}

.homepage .popular-dishes-frame .popular-section .cards-frame {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
}

.homepage .popular-dishes-frame .popular-section .cards-frame .card {
  margin-bottom: 35px;
  cursor: pointer;
  min-height: 350px;
  min-width: 300px;
}
.homepage
  .popular-dishes-frame
  .popular-section
  .cards-frame
  .card
  .card-cover {
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0) 200px
    ),
    linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 300px);
}

.homepage .popular-dishes-frame .no-data {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  color: #e3c08d;
}

.old-price {
  text-decoration: line-through;
  text-decoration-thickness: 2px; /* Adjust the thickness */
  color: black; /* Optional: Set the color of the line */
}

/* NEW DISHES */

.homepage .new-products-frame {
  background: #f8f8ff;
  width: 100%;
  height: auto;
  display: flex;
}

.homepage .new-products-frame .main {
  display: flex;
  flex-direction: column;
}

.homepage .new-products-frame .main .category-title {
  margin-left: 10px;
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  color: #000;
}

.homepage .new-products-frame .main .cards-frame {
  width: 100%;
  margin: 0px 2px 2px 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 30px;
  gap: 30px;
}

.homepage .new-products-frame .main .cards-frame .card {
  height: 335px;
  width: 290px;
  margin: 25px 0;
  cursor: pointer;
}

.homepage .new-products-frame .main .cards-frame .card .product-sale {
  position: absolute;
  margin-top: 10px;
  z-index: 100;
  width: 90px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff9200;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  color: #f8f8ff;
}
.homepage .new-products-frame .main .cards-frame .card .product-detail {
  width: auto;
  height: 49%;
  display: flex;
  justify-content: center;
  margin-top: -12px;
  border-top: 1px solid;
  border-color: #f8f8ff;
  background-color: #fafbfb;
}

.homepage .new-products-frame .main .cards-frame .card .product-detail .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.homepage
  .new-products-frame
  .main
  .cards-frame
  .card
  .product-detail
  .info
  .title {
  width: 65px;
  font-weight: 500;
}

.homepage
  .new-products-frame
  .main
  .cards-frame
  .card
  .product-detail
  .info
  .price {
  margin-left: 10px;
  width: 20px;
  font-weight: 500;
  justify-self: center;
  align-self: center;
}

.homepage
  .new-products-frame
  .main
  .cards-frame
  .card
  .product-detail
  .info
  .divider {
  margin: 0px 10px 0px 10px;
  width: 2px;
  color: #d9d9d9;
}
.homepage
  .new-products-frame
  .main
  .cards-frame
  .card
  .product-detail
  .info
  .views {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.homepage .new-products-frame .no-data {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  color: #e3c08d;
}

/* ADVERTISEMENT */

.homepage .ads-restaurant-frame {
  width: 100%;
  height: 693px;
  margin-top: 30px;
  display: flex;
  overflow: hidden;
  transform: perspective(0px);
  box-shadow: -3px 0px 20px 14px #34343480;
}

.homepage .ads-restaurant-frame .ads-video {
  width: 100%;
  display: flex;
  overflow: hidden;
  transform: scale(1.5);
  box-shadow: 0px -8px 90px rgb(215 215 255);
  background: #0c0e12;
}

/* ACTIVE USERS */

.homepage .active-users-frame {
  width: 100%;
  height: auto;
  display: flex;
  background: #f8f8ff;
}

.homepage .active-users-frame .main {
  display: flex;
  flex-direction: column;
}

.homepage .active-users-frame .category-title {
  margin-left: 10px;
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 43px;
  color: #000;
}
.homepage .active-users-frame .cards-frame {
  width: 100%;
  margin: 15px 2px 2px 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
}

.homepage .cards-frame .card {
  height: 335px;
  width: 290px;
  margin-bottom: 30px;
  cursor: pointer;
}

.homepage .cards-frame .member-nickname {
  width: 65px;
  display: flex;
  justify-content: center;
  align-self: center;
  font-weight: 500;
  color: #000;
}

.homepage .cards-frame .no-data {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  color: #e3c08d;
}

/** EVENTS **/

.homepage .events-frame {
  width: 100%;
  height: 745px;
  background: #f8f8ff;
  display: flex;
}

.homepage .events-frame .events-main {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage .events-frame .events-main .events-text {
  width: auto;
  height: 70px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage .events-frame .events-main .prev-next-frame {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  margin: 40px 0 40px 0;
  height: 25px;
}

.homepage .events-frame .dot-frame-pagination {
  position: relative;
  width: auto;
  display: flex;
}

.homepage .events-frame .events-main .events-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homepage .events-frame .events-info .events-info-frame {
  position: relative;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage .events-frame .events-img {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 10px;
  background-size: cover;
}

.homepage .events-frame .events-desc {
  position: absolute;
  margin-bottom: 7px;
  padding: 10px;
  display: flex;
  width: 90%;
  height: auto;
  bottom: 5px;
  background: #f8f8ff;
  box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  border-radius: 8px;
}

.homepage .events-frame .events-desc .events-bott {
  width: 97%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.homepage .events-frame .events-desc .events-bott .bott-left {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.homepage .events-frame .bott-left .event-title-speaker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homepage .events-frame .event-title-speaker .event-organizator {
  display: flex;
  align-items: center;
}

.homepage .events-frame .event-title-speaker .event-organizator img {
  width: 20px;
  margin-right: 10px;
}

.homepage .events-frame .event-organizator .spec-text-author {
  font-family: "GT Walsheim Pro";
  font-style: normal;
  line-height: 34px;
  color: #140342;
}

.homepage .events-frame .bott-left .text-desc {
  margin-top: 10px;
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #4f547b;
}

.homepage .events-frame .bott-left .bott-info {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  height: 16px;
}

.homepage .events-frame .bott-info .bott-info-main {
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: auto;
  height: 100%;
  font-family: "GT Walsheim Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4f547b;
}

.homepage .events-frame .bott-info .bott-info-main img {
  margin-right: 10px;
}

.homepage .events-frame .swiper-wrapper {
  display: flex;
  flex-direction: row;
}

.homepage .events-frame .swiper-pagination-bullet {
  margin-right: 5px;
  width: 8px;
  height: 8px;
  text-align: center;
  opacity: 1;
  background: #f8f8ff;
  border-radius: 50%;
}

.homepage .events-frame .swiper-pagination-bullet-active {
  background: #343434;
  border-radius: 50%;
}
