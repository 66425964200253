/* PRODUCTS */

.products-page {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #f8f8ff;
}

.products-page .products .list-category-section {
  margin-top: 40px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

/* CARDS */

.products-page .products .product-wrapper {
  margin-left: 25px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.products-page .products .product-wrapper .no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font-style: normal;
}

.products-page .products .product-wrapper .product-card {
  width: 273px;
}

.products-page .products .product-img {
  width: 273px;
  height: 275px;
  border-top-right-radius: 35px;
  background-size: cover;
  position: absolute;
}

.products-page .products .product-img img {
  position: absolute;
  display: block;
  max-width: 100%;
  height: auto;
  z-index: 1;
}

.products-page .products .product-sale {
  position: absolute;
  margin-top: 10px;
  z-index: 100;
  width: 90px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff9200;
  box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  color: #f8f8ff;
}

.products-page .products .shop-btn {
  position: relative;
  padding: 20px;
  top: 213px;
  width: 64px;
  min-width: 64px;
  height: 46px;
  left: 90px;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s ease;
  background: rgb(198 0 255 / 66%);
  border: 2px solid #d7b686;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: #f8f8ff;
}

.products-page .products .view-btn {
  position: relative;
  padding: 5px;
  top: 170px;
  min-width: 40px;
  width: 40px;
  height: 40px;
  left: 180px;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s ease;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
.products-page .products .product-desc {
  position: relative;
  top: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.products-page .products .product-title {
  width: 141px;
  height: 30px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: #343434;
}

.products-page .products .product-price {
  width: 61px;
  height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  color: #e3c08d;
}

.products-page .products .product-img::before {
  border-radius: 0px 50px 0px 0px;
  content: "";
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.products-page .products .product-img:hover::before {
  border-radius: 0px 50px 0px 0px;
  opacity: 0.9;
}

.products-page .products .product-img:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  opacity: 0.9;
}

.products-page .products .product-img:hover .shop-btn {
  opacity: 1;
}

.products-page .products .product-img:hover .view-btn {
  opacity: 1;
}

/* PAGINATION */

.products-page .products .pagination-section {
  margin: 30px 0;
  width: 100%;
  height: 46px;
  align-items: center;
  justify-items: center;
}

/* BRAND LOGOS */

.products-page .brands-logo {
  width: 100%;
  height: 630px;
  padding-bottom: 80px;
  background-color: #0d1518;
}
.products-page .brands-logo .brands-title {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  padding-top: 60px;
  color: #e3c08d;
}

.products-page .brands-logo .cards-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  gap: 70px;
}

.products-page .brands-logo .cards-holder .brand-card {
  width: 250px;
  height: 340px;
  border-radius: 20px;
  background: #0d1518;

  box-shadow: 0px 0px 22px 0px #e4d4d4;
}

/* ADDRESS */

.products-page .address {
  width: 100%;
  height: auto;
  color: #f8f8ff;
  display: flex;
}

.products-page .address .address-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.products-page .address .address-area .iframe {
  margin-bottom: 60px;
}

.products-page .address .category-title {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  padding-top: 20px;
  color: #343434;
}

/* CHOSEN PRODUCT */

.products-page .chosen-product {
  position: relative;
  width: 100%;
  height: auto;
  background: #f8f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products-page .chosen-product .product-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 150px;
}

.products-page .chosen-product .chosen-product-slider {
  width: 48%;
  height: 480px;
  background: #ffffff;
  box-shadow: 9px 13px 14px 5px rgba(0, 0, 0, 0.25),
    inset -1px 2px 10px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  overflow: hidden;
}

.products-page .chosen-product .chosen-product-slider .swiper-area {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  width: 100%;
  height: 1020px;
}

.products-page
  .chosen-product
  .chosen-product-slider
  .swiper-area
  .slider-image {
  width: 100%;
  height: 100%;
}

.products-page .chosen-product .chosen-product-info {
  padding: 30px 40px;
  width: 49%;
  height: 480px;
  display: flex;
  background: #ffffff;
  box-shadow: 9px 13px 14px 5px rgba(0, 0, 0, 0.25),
    inset -1px 2px 10px 3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.products-page .chosen-product .chosen-product-info .info-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.products-page .chosen-product .info-box .product-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.products-page .chosen-product .info-box .resto-name {
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 15px;
  color: #979797;
}

.products-page .chosen-product .info-box .rating-box {
  margin-top: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.products-page .chosen-product .rating-box .star-box {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.products-page .chosen-product .rating-box .evaluation-box {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.products-page .chosen-product .rating-box .evaluation-box .product-view {
  display: flex;
  align-items: center;
}

.products-page .chosen-product .info-box .product-desc {
  height: 112px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.products-page .chosen-product .info-box .product-price {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.products-page .chosen-product .product-price span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 22px;
  color: #000000;
}

.products-page .chosen-product .button-box {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.products-page .chosen-product .button-box button {
  width: 230px;
  height: 44px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #f8f8ff;
}

.products-page .chosen-product .title {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin-top: 40px;
}
